import React from 'react'
import { withSiteData } from 'react-static'
import { Link } from '@reach/router'

import NavLink from './NavLink'

const Nav = ({ data }) => {
  return (
    <nav className='nav'>
      <div className='nav-container'>
        <Link to="/" className='logo-wrapper'>
          <img className='logo' src={data.logo} alt='Pineview Builders logo' />
        </Link>
        <NavLink to="/meet-mike/">Meet Mike</NavLink>
        <NavLink to="/contact/">Contact</NavLink>
        <NavLink to="/gallery/">Gallery</NavLink>
      </div>
    </nav>
  )
}

export default withSiteData(Nav)

import React from 'react'
import { Root, Routes } from 'react-static'

import Nav from './components/Nav'

import './sass/app.scss'

function App() {
  return (
    <Root>
      <Nav />
      <div className='container'>
        <Routes />
      </div>
    </Root>
  )
}

export default App

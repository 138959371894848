import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const NavLink = ({ to, children }) => {
  return (
    <Link
      className='nav-link'
      to={to}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return isCurrent || isPartiallyCurrent ? { className: 'nav-link active' } : null
      }}>
      {children}
    </Link>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default NavLink
